import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Stack, Table } from "react-bootstrap";
import Plot from "react-plotly.js";
import { getIndicatorBanchmark } from "../util/common";

const IndicatorChartWithBanchmark = ({
  title,
  description,
  chartData,
  layout,
  unit,
  source,
  refToSrcoll,
  availableCountryNumber,
  totalCountryNumber,
  summaryTableData,
  returnTo,
}) => {
  let benchmark = getIndicatorBanchmark(title);
  const legendImageUrl = "../css/legend.png"; // Replace with the actual path to your PNG image

  const renderUnitOfBenchmark = (unit, content) => {
    var number = Number(content);
    if (unit.includes("%") && !isNaN(number)) {
      number = number < 1 ? number * 100 : number;

      return number + "" + unit;
    }
    return content;
  };

  return (
    <>
      <Row className="mt-3" ref={refToSrcoll}>
        <h4 className="subTitle">
          <strong>{title}</strong>
        </h4>
      </Row>
      <Row className="mt-1 ">
        <span className="description">{description}</span>
      </Row>
      <Row className="mt-1 ">
        <Col className="py-3 ms-3">
          
        {unit && <Stack direction="horizontal" gap={2}>
            <p style={{ fontSize: 13, fontFamily: "PoppinsBold" }}>Unit:</p>
           <p style={{ fontSize: 13 }}> {unit}</p>
          </Stack>}
          <Stack direction="horizontal" gap={2}>
            <p style={{ fontSize: 13, fontFamily: "PoppinsBold" }}>Source:</p>
            <p style={{ fontSize: 13 }}>
              <a href={source?.link}>{source?.title}</a>
            </p>
          </Stack>
          {benchmark && (
            <Stack direction="horizontal" style={{ alignItems: "normal" }} gap={2}>
              <p style={{ fontSize: 13, fontFamily: "PoppinsBold" }}>Benchmark:</p>
              <div>
                {benchmark?.threshold ? <p style={{ fontSize: 13 }}>{renderUnitOfBenchmark(unit, benchmark?.threshold)}</p> : null}
                {benchmark?.note && <p style={{ fontSize: 13 }}>{benchmark?.note}</p>}
                {benchmark?.reference && <p style={{ fontSize: 13, maxWidth: "200px" }}>{benchmark?.reference}</p>}
              </div>
            </Stack>
          )}
          <Stack direction="horizontal" gap={2}>
            <p style={{ fontSize: 13, fontFamily: "PoppinsBold" }}>Countries with available data:</p>
            <p style={{ fontSize: 13 }}>
              {" "}
              {availableCountryNumber} (out of {totalCountryNumber}){" "}
            </p>
          </Stack>
          <Table striped borderless hover size="sm" style={{ fontSize: 13, fontFamily: "Poppins" }}>
            <thead>
              <tr>
                <th style={{ fontWeight: 100 }}>Country</th>
                <th style={{ fontWeight: 100 }}>Latest Data Available</th>
                <th style={{ fontWeight: 100 }}> {unit ? "Value (" + unit + ")" : "Value"}</th>
                <th style={{ fontWeight: 100 }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {summaryTableData &&
                Object.entries(summaryTableData).map(([countryName, country]) => (
                  <tr key={title + "---" + countryName}>
                    <td>{countryName}</td>
                    <td>{isNaN(country.year) ? "2022" : country.year}</td>
                    <td>{country.value}</td>
                    <td style={country.status === "Amplifier" ? { color: "#d7263d" } : { color: "#008d17" }}>{country.status}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Plot data={chartData} layout={layout} />
          <img src={require("../css/legend.png")} alt="Description of the image" style={{ maxWidth: "100%", paddingLeft: "220px" }} />
        </Col>
      </Row>
      <Row>
        <Col>
          {returnTo && (
            <u style={{ fontSize: 13, marginTop: 15, color: "black", cursor: "pointer" }} onClick={returnTo}>
              Return to selector
            </u>
          )}
        </Col>
      </Row>
    </>
  );
};

export default IndicatorChartWithBanchmark;
