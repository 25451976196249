import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Button, Stack, Nav } from "react-bootstrap";

const Layout = ({ children, selectedTab = 0 }) => {
  const navigate = useNavigate();

  return (
    <Container fluid>
      <Row>
        <Col className="text-left header-container">
          <h1 className="text-white py-5 px-3" style={{ fontWeight: "700", fontFamily: "PoppinsBold", cursor:"pointer" }} onClick={() => navigate("/")}>
            Pacific Food Security Dashboard
          </h1>
        </Col>
        <Col className="header-container py-5" style={{ maxWidth: "416px" }}>
          <Stack direction="horizontal" gap={2} style={{ background: "white", padding: "8px", borderRadius: "10px" }}>
            <Button variant={selectedTab === 0 ? "secondary" : "transparent-light"} href="/">
              Country Data
            </Button>
            <Button variant={selectedTab === 1 ? "secondary" : "transparent-light"} href="/pillars">
              About Food Security Pillars
            </Button>
          </Stack>
        </Col>
      </Row>
      <Container>{children}</Container>
    </Container>
  );
};

export default Layout;
