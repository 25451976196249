import './css/main.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CustomRoutes from './components/routing/CustomRoutes';

const App = () => {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <CustomRoutes />
            </BrowserRouter>
        </React.StrictMode>
    );
}

export default App;
