import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Stack, FormLabel, Form } from "react-bootstrap";
import Plot from "react-plotly.js";

const IndicatorChart = ({ title, description, chartData, layout, returnTo, refToSrcoll }) => {
  //console.log(title,layout);
  return (
    <>
      <Row className="m-2 mt-5" ref={refToSrcoll}>
        <h4 className="subTitle">
          <strong>{title}</strong>
        </h4>
      </Row>
      <Row className="m-2 mt-1 ">
        <Col xs={6}>
          <Stack>
            <span style={{ fontSize: 16 }}>{description}</span>
            {returnTo && (
              <u style={{ fontSize: 13, marginTop: 15, color: "black", cursor:"pointer" }} onClick={returnTo}>
                Return to selector
              </u>
            )}
          </Stack>
        </Col>
        <Col xs={6}>
          <Plot data={chartData} layout={layout} />
        </Col>
      </Row>
    </>
  );
};

export default IndicatorChart;
