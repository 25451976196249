import {getValueNumber} from "../util/common";

const ColorStatus = ({ value, text, style, height, fontSize, width }) => {

  const getColor = (_value) => {
    let value = getValueNumber(_value);
    if (value < 1 && value > -1) return "#f2f2f2";
    else if (value >= 1 && value < 4) return "#5dbf25";
    else if (value >= 4 && value < 10) return "#008d17";
    else if (value >= 10 && value) return "#004d08";
    else if (value > -4 && value <= -1) return "#ffde2a";
    else if (value > -10 && value <= -4) return "#fe962a";
    else if (value <= -10) return "#d7263d";
  };

  const getTextColor = (_value) => {
    let value = getValueNumber(_value);
    if (value < 1 && value > -1) return "#000000";
    else if (value >= 1 && value < 4) return "#ffffff";
    else if (value >= 4 && value < 10) return "#ffffff";
    else if (value >= 10 && value) return "#ffffff";
    else if (value > -4 && value <= -1) return "#000000";
    else if (value > -10 && value <= -4) return "#000000";
    else if (value <= -10) return "#ffffff";
  }

  return (
    <div
      className="color-status"
      style={{
        ...style,
        color: getTextColor(value),
        backgroundColor: getColor(value),
        height: height ? height : "100%",
        width: width ? width : height ? height : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: fontSize ? fontSize : "1.5rem",
      }}
    >
      {text !== undefined ? text : value}
    </div>
  );
};

export default ColorStatus;
