import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, Button, Stack, FormLabel, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import ColorStatus from "./ColorStatus";
import { StringUpperCaseFirstLetter, getPillarsAbout, getCountryIndicatorPrecentage } from "../util/common";

const PillarRow = ({ className, countryName, countryScore, pillar, credibility, missingData }) => {
  console.log(pillar, credibility, credibility[pillar]);

  const getPrecentageText = (countryName, pillar, indicator, color) => {
    let data = getCountryIndicatorPrecentage(countryName, pillar, indicator);

    let unit = pillar!=="Stability"? "%":"";

    if (data) {
      return (<>: <span style={{color:color}}>{data.value.toFixed(2)}{unit}</span> (vs {data.avg.value.toFixed(2)}{unit} Aggregated Pacific) </>);
    }

    return "";
  };

  return (
    <Row className={className}>
      <Col>
        <Stack direction="horizontal">
          <Stack className="text-center" style={{ minWidth: 140, maxWidth: 140 }}>
            <ColorStatus value={countryScore[pillar]} width={123} height={96}></ColorStatus>
          </Stack>
          <Stack>
            <h5 className="subTitle">{StringUpperCaseFirstLetter(pillar)}</h5>
            <p className="mt-2 description">{getPillarsAbout(pillar)}</p>
            <Container>
              <Row className="">
                <Col xs={4}>
                  <h5 style={{ fontSize: 16 }}>
                    Mitigators
                    <OverlayTrigger placement="top" overlay={<Tooltip>Mitigators help ease the food insecurity conditions.</Tooltip>}>
                      <FontAwesomeIcon icon={faCircleInfo} color="#000000" className="" style={{ height: "14px", marginLeft: "10px" }} />
                    </OverlayTrigger>
                  </h5>
                  {credibility[pillar] && credibility[pillar].Mitigator ? (
                    Object.keys(credibility[pillar].Mitigator).map((m, index) => (
                      <p className="mb-2" key={"cp-m" + index} style={{ fontSize: 13, marginBottom: 3 }}>
                        <span style={{ textDecoration: "underline" }}>{m}</span>
                        {getPrecentageText(countryName, pillar, m,"green")}
                      </p>
                    ))
                  ) : (
                    <span style={{ fontSize: 13 }}>None</span>
                  )}
                </Col>
                <Col xs={4}>
                  <h5 style={{ fontSize: 16 }}>
                    Amplifiers
                    <OverlayTrigger placement="top" overlay={<Tooltip>Amplifiers worsen the food insecurity conditions.</Tooltip>}>
                      <FontAwesomeIcon icon={faCircleInfo} color="#000000" className="" style={{ height: "14px", marginLeft: "10px" }} />
                    </OverlayTrigger>{" "}
                  </h5>
                  {credibility[pillar] && credibility[pillar].Amplifier ? (
                    Object.keys(credibility[pillar].Amplifier).map((m, index) => (
                      <p className="mb-2" key={"cp-a" + index} style={{ fontSize: 13, marginBottom: 3 }}>
                      <span style={{ textDecoration: "underline" }}>{m}</span>
                        {getPrecentageText(countryName, pillar, m,"red")} ({credibility[pillar].Amplifier[m]} data)
                      </p>
                    ))
                  ) : (
                    <span style={{ fontSize: 13 }}>None</span>
                  )}
                </Col>
                <Col xs={4}>
                  <h5 style={{ fontSize: 16 }}>
                    Data Unavailable
                    <OverlayTrigger placement="top" overlay={<Tooltip>Data is not available for the below indicators.</Tooltip>}>
                      <FontAwesomeIcon icon={faCircleInfo} color="#000000" className="" style={{ height: "14px", marginLeft: "10px" }} />
                    </OverlayTrigger>{" "}
                  </h5>
                  {missingData ?
                    missingData.map((m, index) => (
                      <p className="mb-2" key={"cp-d" + index} style={{ fontSize: 13, marginBottom: 3 }}>
                        {m}
                      </p>
                    )):(<span style={{ fontSize: 13 }}>None</span>)}
                  
                </Col>
              </Row>
            </Container>
          </Stack>
        </Stack>
      </Col>
    </Row>
  );
};

export default PillarRow;
