import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

const Loading = () => {
    return (
        <Container style={{ height: "100%" }}>
            <Row style={{ height: "100%" }}>
                <Col style={{ height: "100%" }} className='d-flex justify-content-center align-items-center'>
                    <Spinner role="status" style={{ width: "75px", height: "75px" }}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        </Container>
    )
}

export default Loading;