import { Row } from "react-bootstrap";

const FooterRow = ({ className }) => {
  return (
    <Row className={className}>
      <span style={{ fontSize: "11px" }}>
        Definitions of pillars are from HLPE. 2020. Food security and nutrition: building a global narrative towards 2030. A report by the High Level
        Panel of Exprts on Food Security and Nutrition of the Committee on World Food Security, Rome.
      </span>
    </Row>
  );
};

export default FooterRow;
