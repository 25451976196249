import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import './css/main.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Register a service worker
// THis is used to store the access and refresh tokens
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        //Check if a Service Worker is already registered
        navigator.serviceWorker.getRegistration().then(function (registration) {
            if (!registration) {
                // If no Service Worker is registered, then register it
                navigator.serviceWorker.register('serviceWorker.js')
                    .then(function (registration) {
                        console.log('Service Worker registered successfully:', registration);
                    })
                    .catch(function (error) {
                        console.error('Service Worker registration failed:', error);
                    });
            } else { // service worker already exists
                // Perform a soft reload to load everything from the servie worker and get a consistent set of resources.
                // we need to do this becuase otherwise on hard reload the serviceWorkers controller is null
                if (registration.active && !navigator.serviceWorker.controller) {
                    window.location.reload();
                }
                console.log('Service Worker is already registered:', registration);
            }
        });
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
