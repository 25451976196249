import React, { useRef } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Stack, FormLabel, Form } from "react-bootstrap";
import { getPillars, getIndicatorNames, getPillarsAbout} from "../util/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const IndicatorBlock = ({ refForScrollTo, onClickInidcator, isChartAvailable, countries, isRecommentedIndicator=undefined }) => {
  return (
    <>
      <Row className="m-2 mt-4 " ref={refForScrollTo}>
        {getPillars().map((pillar, index) => {
          return (
            <Col key={"pillar" + index}>
              <h5 className="subTitle">{pillar}</h5>
              <p className="description">{getPillarsAbout(pillar)}</p>
            </Col>
          );
        })}
      </Row>

      <Row className="m-2 mt-2 ">
        {getPillars().map((pillar, index) => (
          <Col key={"s-pillar" + index}>
            <Stack>
              {getIndicatorNames(pillar).map((indicator, _index) =>
                isChartAvailable(pillar, indicator) ? (
                  <u
                    key={"s-indicator" + _index}
                    style={{ color: "black", fontSize: "13px", cursor: "pointer" }}
                    onClick={(e) => {
                      onClickInidcator(pillar, indicator);
                    }}
                  >
                    {countries && " "}
                    {(countries && isRecommentedIndicator && isRecommentedIndicator(countries,pillar, indicator)) ? <strong> <FontAwesomeIcon icon={faStar} color="#fe962a" className="" style={{ height: "14px", marginLeft: "-20px", marginRight:"5px" }} />{indicator}</strong>:indicator}
                  </u>
                ) : (
                  <a key={"s-indicator" + _index} style={{ color: "gray", fontSize: "13px" }}>
                    {countries && " "}
                    {indicator}
                  </a>
                )
              )}
            </Stack>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default IndicatorBlock;
