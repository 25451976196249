const indicatorsChartData = require("../data/chart-indicators.json");
const countriesCredibility = require("../data/countriesCredibility.json");
const countriesScores = require("../data/overall.json");
export const indicatorsOfCountries = require("../data/chart-indicatorsOfCountries.json");
const indicatorsBenchmark = require("../data/indicator-benchmark.json");
const fundingIndicators = require("../data/funding-indicators.json");

function getCombinations(obj) {
  const result = [];

  // Iterate over level 1 keys
  for (const level1Key in obj) {
    if (obj.hasOwnProperty(level1Key)) {
      const level1Value = obj[level1Key];

      // Iterate over level 2 keys
      for (const level2Key in level1Value) {
        if (level1Value.hasOwnProperty(level2Key)) {
          const level2Value = level1Value[level2Key];

          // Create a combination and push it to the result array
          result.push({ pillar: level1Key, indicator: level2Key });
        }
      }
    }
  }

  return result;
}

export const isChartAvailable = (countries, pillar, indicator) => {
  let lines = countries.filter((_country) => indicatorsOfCountries[pillar][indicator][_country].x.length > 0);
  return lines.length > 0;
};

export const isFundingArea = (countries, pillar, indicator) => {
  let scores = countries.map((_country) => ({
    country: _country,
    score: Number(fundingIndicators[_country].OverallScore),
  }));

  let min = scores.reduce((minObj, currentObj) => {
    return minObj.score < currentObj.score ? minObj : currentObj;
  });

  let country = min.country;

  return fundingIndicators[country]["Pillars"] && fundingIndicators[country]["Pillars"][pillar] && fundingIndicators[country]["Pillars"][pillar].includes(indicator);
};

export const getFundAreaOfCountry = (country) => {
  return fundingIndicators[country]["Pillars"];
}

export const IsNullOrUndefined = (obj) => {
  return obj === null || obj === undefined;
};

export const StringIsEmptyNullOrUndefined = (obj) => {
  return typeof obj !== "string" || obj === null || obj === undefined || obj.length === 0;
};

export const StringUpperCaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getPillars = () => {
  return Object.keys(indicatorsChartData);
};

export const getIndicatorNames = (pillar) => {
  return Object.keys(indicatorsChartData[pillar]);
};

export const getAllIndicatorNames = () => {
  let indicators = [];
  for (const pillar of getPillars()) {
    indicators = indicators.concat(getIndicatorNames(pillar));
  }
  return indicators;
};

export const getIndicatorData = (pillar, indicator) => {
  return indicatorsChartData[pillar][indicator];
};

export const getIndicatorChartData = (pillar, indicator) => {
  return indicatorsChartData[pillar][indicator].data;
};
export const getIndicatorAbout = (pillar, indicator) => {
  return indicatorsChartData[pillar][indicator].about;
};

export const isIndicatorChartValid = (pillar, indicator) => {
  return (
    indicatorsChartData[pillar][indicator].data &&
    (indicatorsChartData[pillar][indicator].data[0].x?.length > 0 || indicatorsChartData[pillar][indicator].data[1].x?.length > 0)
  );
};

export const getIndicatorChartYheight = (pillar, indicator) => {
  return indicatorsChartData[pillar][indicator].data[0].y.concat(indicatorsChartData[pillar][indicator].data[1].y).length;
};

export const getIndicatorChartAvaialbleCountryNumber = (pillar, indicator) => {
  return indicatorsChartData[pillar][indicator] && indicatorsChartData[pillar][indicator].table
    ? Object.keys(indicatorsChartData[pillar][indicator].table).length
    : 0;
};

export const getPillarsAbout = (pillar) => {
  switch (pillar) {
    case "Availability":
      return "Having a quantity and quality of food sufficient to satisfy the dietary needs of individuals, free from adverse substances and acceptable within a given culture, supplied through domestic production or imports.";
    case "Accessibility":
      return "Having personal or household financial means to acquire food for an adequate diet at a level to ensure that satisfaction of other basic needs are not threatened or compromised; and that adequate food is accessible to everyone, including vulnerable individuals and groups.";
    case "Utilization":
      return "Having an adequate diet, clean water, sanitation and health care to reach a state of nutritional well-being where all physiological needs are met.";
    case "Stability":
      return "Having the ability to ensure food security in the event of sudden shocks (e.g. an economic, health, conflict or climatic crisis) or cyclical events (e.g. seasonal food insecurity).";
    default:
  }
};

export const getAllPillarIndicatorCombinations = () => {
  return getCombinations(indicatorsChartData);
};

export const getCountryMissingData = (countryName, pillar) => {
  const country = countriesCredibility[countryName];
  const allIndicators = getIndicatorNames(pillar);
  let availableData = [];

  if (country[pillar] && country[pillar]["Amplifier"]) availableData = availableData.concat(Object.keys(country[pillar]["Amplifier"]));
  if (country[pillar] && country[pillar]["Mitigator"]) availableData = availableData.concat(Object.keys(country[pillar]["Mitigator"]));

  return allIndicators.filter((i) => !availableData.includes(i));
};

export const getCountryScore = (countryName, pillar) => {
  if (pillar) {
    return countriesScores.find((c) => c.name === countryName)[pillar];
  }
  return countriesScores.find((c) => c.name === countryName);
};

export const getCountryFlagShortName = (countryName) => {
  return countryName ? countriesScores.find((c) => c.name === countryName).flag : null;
};

export const getValueNumber = (value) => {
  if (value === "0**") return 0;
  else return Number(value);
};

export const getContryOverallScore = (countryName) => {
  let value = countriesScores.find((c) => c.name === countryName)["Food Security"];
  return getValueNumber(value);
};

export const getAllCountryNumber = () => {
  return countriesScores.length;
};

export const getDefaultCountryCredibility = (countryName) => {
  let countriesNames = Object.keys(countriesCredibility);
  if (countriesNames.find((c) => c.name === countryName)) {
    return countriesCredibility[countryName];
  } else {
    return countriesCredibility[countriesNames[0]];
  }
};

export const getDefaultCountryName = () => {
  return Object.keys(countriesCredibility)[0];
};

export const getCountryCredibility = (countryName) => {
  return countriesCredibility[countryName];
};

export const getAllCountryNames = () => {
  return Object.keys(countriesCredibility);
};

export const getIndicatorBanchmark = (indicator) => {
  return indicatorsBenchmark[indicator];
};

export const getCountryIndicatorPrecentage = (countryName, pillar, indicator) => {
  let countryData = indicatorsOfCountries[pillar][indicator][countryName];
  if(countryData && countryData.x.length > 0) {
    let pacificAvg = indicatorsOfCountries[pillar][indicator]["Pacific"];
    
    return {
      year: countryData.x[countryData.x.length-1],
      value: countryData.y[countryData.y.length-1],
      avg:{
        year: pacificAvg.x[pacificAvg.x.length-1],
        value: pacificAvg.y[pacificAvg.y.length-1]
      }
    }
  }
  return null;
}