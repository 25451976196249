import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faPlaneArrival } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, Button, Stack, Nav } from "react-bootstrap";
import ColorStatus from "../components/ColorStatus";
import ColorStatusLegend from "../components/ColorStatusLegend";
import Layout from "../components/Layout";
import HR from "../components/HR";

const countries = require("../data/overall.json");

const Home = () => {
  const navigate = useNavigate();

  const [comparisonCountries, setComparisonCountries] = useState([]);

  const logout = () => {
    navigate("/");
  };

  const handleAddToComparison = (country) => {
    if (!isAddedToComparison(country)) {
      setComparisonCountries([...comparisonCountries, country]);
    } else {
      setComparisonCountries(comparisonCountries.filter((c) => c !== country));
    }
  };

  const isAddedToComparison = (country) => {
    return comparisonCountries.includes(country);
  };

  return (
    <Layout className="container">
      <Row className="mt-5 mb-4">
        <Col>
          <span className="pageTitle">ABOUT THIS TOOL</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            The PFSD visualizes the data of Pacific nations for different indicators in four pillars of food security - availability, accessibility,
            utilization and stability.
          </p>
        </Col>
        <Col>
          <p>
            You can view our data for each individual country by clicking on the country's name, or alternatively, you can compare countries to see a
            direct comparison.
          </p>
        </Col>
        <Col>
          <p>
            You can also learn more about the Food Security Pillars by clicking on the pillar's name below, or by clicking on the "About Food Security
            Pillars" tab above.
          </p>
        </Col>
      </Row>

      <HR className="mt-4" />

      <Row className="mt-5 mb-4">
        <Col xs={8}>
          <span className="pageTitle">Overall Country Rating</span>
        </Col>
        <Col></Col>
      </Row>
      <Row className="mb-4">
        <Col xs={5}>
        <Stack gap={3}>
          <Stack direction="horizontal" gap={1} style={{ height: "100%" }}>
            <span className="me-4">Legend</span>
            <ColorStatusLegend alignLeft={true}></ColorStatusLegend>
          </Stack>
          <span style={{fontSize:"13px"}}>NOTE: ** means no data available for any indicators in the pillar.</span>
          </Stack>
        </Col>
        <Col xs={2} className="text-center">
          <h4 style={{ fontSize: "18px", color: "var(--primary-color)", fontFamily: "PoppinsBold" }}> Overall Food Security</h4>
        </Col>
        <Col xs={5} className="text-center">
          <Stack direction="horizontal" gap={0} style={{ height: "100%" }}>
            <a className="subTitle" style={{ width: "25%" }} href="/pillars">
              Availability
            </a>
            <a className="subTitle" style={{ width: "25%" }} href="/pillars">
              Accessibility
            </a>
            <a className="subTitle" style={{ width: "25%" }} href="/pillars">
              Utilization
            </a>
            <a className="subTitle" style={{ width: "25%" }} href="/pillars">
              Stability
            </a>
          </Stack>
        </Col>
      </Row>
      {countries?.length > 0 &&
        countries.map((country, index) => (
          <Row key={index} className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Col xs={3} className="text-left">
              <u
                style={{
                  color: "var(--primary-color)",
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: isAddedToComparison(country.name) ? "PoppinsBold" : "",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/country", { state: { country: country.name } })}
              >
                {country.name}
              </u>
            </Col>
            <Col xs={2} className="text-center">
              <button
                style={{ width: "100%", fontSize: "13px", fontWeight: "600" }}
                className={!isAddedToComparison(country.name) ? "btn btn-outline-light" : "btn btn-dark"}
                onClick={() => handleAddToComparison(country.name)}
              >
                {isAddedToComparison(country.name) ? "REMOVE" : "ADD TO COMPARISON"}
              </button>
            </Col>
            <Col xs={2} className="text-center" style={{ height: "96px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ColorStatus value={country["Food Security"]} width="123px"></ColorStatus>
            </Col>
            <Col xs={5} className="text-center" style={{ height: "96px" }}>
              <Stack direction="horizontal" gap={1} style={{ height: "100%" }}>
                <ColorStatus style={{ width: "25%" }} width="123px" value={country.Availability}></ColorStatus>
                <ColorStatus style={{ width: "25%" }} width="123px" value={country.Accessibility}></ColorStatus>
                <ColorStatus style={{ width: "25%" }} width="123px" value={country.Utilization}></ColorStatus>
                <ColorStatus style={{ width: "25%" }} width="123px" value={country.Stability}></ColorStatus>
              </Stack>
            </Col>
          </Row>
        ))}

      {comparisonCountries?.length > 1 && (
        <>
          <div style={{ display: "block", height: "150px" }}></div>

          <div style={{ position: "fixed", bottom: "0", left: "0", background: "white", width: "inherit" }}>
            <HR className="mt-0" />

            <Container>
              <Row>
                <span className="pageTitle">Compare Countries</span>
              </Row>
              <Row>
                <Col className="py-0 px-3">
                  <h5 style={{ color: "var(--primary-color)", fontSize: "13px", fontWeight: "600" }}>Selected countries</h5>
                </Col>
              </Row>
              <Row className="py-0">
                <Col xs={6} className="py-0 px-3">
                  <div className="p-3" style={{ background: "#f2f2f2" }}>
                    {comparisonCountries?.length > 0 ? (
                      <span>{comparisonCountries.join(", ")}</span>
                    ) : (
                      <p style={{ fontSize: "13px", fontFamily: "PoppinsItalic" }}>
                        None - add countries using the "Add to Comparison" buttons next to the country name above!
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={6} className="py-2 px-3">
                  <button className="btn btn-dark" onClick={() => navigate("/compare", { state: { countries: comparisonCountries } })}>
                    COMPARE
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}

      {comparisonCountries?.length <= 1 && (
        <div>
          <HR className="mt-5 mb-5" />

          <Row>
            <span className="pageTitle">Compare Countries</span>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="py-3 px-3">
              <h5 style={{ color: "var(--primary-color)", fontSize: "13px", fontWeight: "600" }}>Selected countries</h5>
              <div className="p-3" style={{ background: "#f2f2f2" }}>
                {comparisonCountries?.length > 0 ? (
                  comparisonCountries.map((country, index) => (
                    <p className="mt-1 mb-1" key={index}>
                      {country}
                    </p>
                  ))
                ) : (
                  <p style={{ fontSize: "13px", fontFamily: "PoppinsItalic" }}>
                    None - add countries using the "Add to Comparison" buttons next to the country name above!
                  </p>
                )}
              </div>
            </Col>
            <Col xs={6}>
              <p style={{ fontSize: "16px" }}>
                To compare countries, click "Add to Comparison" for all of the countries that you're interested in, and then hit the "Compare" button
                below to view the comparison.
              </p>
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default Home;
