import { Col, Row } from "react-bootstrap";

const HR = ({ className }) => {
  return (
    <Row className={className}>
      <Col style={{ background: "#5dbf25ed", height: "4px" }}></Col>
      <Col style={{ background: "#fde045", height: "4px" }}></Col>
      <Col style={{ background: "#008d17", height: "4px" }}></Col>
      <Col style={{ background: "#231f20", height: "4px" }}></Col>
      <Col style={{ background: "#fe962a", height: "4px" }}></Col>
    </Row>
  );
};

export default HR;
