import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import globalRouter from "../../Helpers/GlobalRouter";
import React from "react";
import RestrictedRoute from "./RestrictedRoute";
import Home from "../../pages/Home";
import Country from "../../pages/Country";
import Pillars  from "../../pages/Pillars";
import Compare from "../../pages/Compare";

const CustomRoutes = () => {
    // Expose the navigate functionality to non-react components by storing it
    // in the GlobalRouter helper
    const navigate = useNavigate();
    globalRouter.navigate = navigate;

    return (
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/country" element={<Country />} />
        <Route path="/pillars" element={<Pillars />} />
        <Route path="/compare" element={<Compare />} />

            <Route path="/main" element={
                <RestrictedRoute><Home /></RestrictedRoute>
            } />

            {/*
             DEFAULT PATH - if the current path does not match any routes path above this, this route is used 
             Navigate to the map page, since the map route is wrapped in a RestrictedRoute component, if the user is not logged in
             they will be redirected to the login page
             */}
            <Route path="*" element={
                <Navigate to="/main" />
            } />
        </Routes>
    )
}
export default CustomRoutes;