import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Stack, FormLabel, Form } from "react-bootstrap";
import ColorStatus from "../components/ColorStatus";
import ColorStatusLegend from "../components/ColorStatusLegend";
import Layout from "../components/Layout";
import FooterRow from "../components/FooterRow";
import Plot from "react-plotly.js";
import IndicatorBlock from "../components/IndicatorBlock";
import {
  getPillars,
  getIndicatorNames,
  getPillarsAbout,
  getIndicatorAbout,
  getIndicatorData,
  getAllPillarIndicatorCombinations,
  getContryOverallScore,
  isChartAvailable,
  indicatorsOfCountries,
  isFundingArea,
} from "../util/common";
import IndicatorChart from "../components/IndicatorChart";
import HR from "../components/HR";

const countries = require("../data/overall.json");

const Compare = () => {
  const scrollToIndicatorBlockRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  if (state.countries === undefined || state.countries.length === 0) {
    navigate("/");
    // return null;
  }

  const [comparisonCountries, setComparisonCountries] = useState(
    countries
      .filter((c) => state.countries.includes(c.name))
      .sort(function (a, b) {
        return getContryOverallScore(a.name) - getContryOverallScore(b.name);
      })
  );
  // console.log(comparisonCountries);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pillarIndicatorRefs = useRef(getAllPillarIndicatorCombinations().map(() => React.createRef()));

  const getPillarIndicatorRef = (pillar, indicator) => {
    return pillarIndicatorRefs.current[getAllPillarIndicatorCombinations().findIndex((p) => p.pillar === pillar && p.indicator === indicator)];
  };

  const gotoPillarIndicator = (pillar, indicator) => {
    // console.log("gotoPillarIndicator", pillar, indicator);
    getPillarIndicatorRef(pillar, indicator).current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!state || !state.countries) {
    navigate("/");
    return null;
  }

  const scrollToSelector = () => {
    if (scrollToIndicatorBlockRef.current) {
      scrollToIndicatorBlockRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getDataAvailabilityChartData = () => {
    let chartData = require("../data/chart-countriesOverall.json");
    //console.log("chart-countriesOverall.json:",chartData[0]);
    let countryIndexes = comparisonCountries.map((c) => chartData[0].y.indexOf(c.name));
    chartData[0].y = chartData[0].y.filter((_, index) => countryIndexes.includes(index));
    chartData[0].x = chartData[0].x.filter((_, index) => countryIndexes.includes(index));
    chartData[0]["text"] = chartData[0].x;
    chartData[0]["textposition"] = "auto";

    countryIndexes = comparisonCountries.map((c) => chartData[1].y.indexOf(c.name));
    chartData[1].y = chartData[1].y.filter((_, index) => countryIndexes.includes(index));
    chartData[1].x = chartData[1].x.filter((_, index) => countryIndexes.includes(index));
    chartData[1]["text"] = chartData[1].x;
    chartData[1]["textposition"] = "auto";
    //console.log(comparisonCountries, countryIndexes,chartData);
    return chartData;
  };

  const fillGap = (trace) => {
    if (trace && trace.x && trace.x.length > 1) {
      let start = Number(trace.x[0]);
      let end = Number(trace.x[trace.x.length - 1]);

      let x = [];
      let y = [];

      if (!isNaN(start)) {
        x.push("" + start);
        y.push(trace.y[0]);

        let current = start + 1;
        while (current !== end) {
          let index = trace.x.indexOf("" + current);
          if (index === -1) {
            x.push("" + current);
            y.push(0);
          } else if (index < trace.y.length) {
            //console.log("index:", index, trace.y.length);
            x.push("" + current);
            y.push(trace.y[index]);
          }
          current++;
        }

        x.push("" + end);
        y.push(trace.y[trace.y.length - 1]);

        trace.x = x;
        trace.y = y;
      }
    }
    return trace;
  };

  const getChartData = (pillar, indicator) => {
    let lines = state.countries.map((_country) => ({
      line: indicatorsOfCountries[pillar][indicator][_country],
      country: _country,
    }));

    lines = lines.filter((data) => data?.line.x.length > 0);
    let traces = lines.map((data, index) => {
      return {
        x: data?.line.x,
        y: data?.line.y,
        type: "bar",
        name: data?.country,
        showlegend: true,
      };
    });

    traces = traces.map((trace) => fillGap(trace));

    if (traces.length > 0 && traces[0]) {
      if (traces[0].x.length < 3) {
        let start = Number(traces[0].x[0]);
        if (!isNaN(start)) {
          let end = Number(traces[0].x[traces[0].x.length - 1]);
          traces[0].x = ["" + (start - 2), "" + (start - 1), ...traces[0].x, "" + (end + 1)];
          traces[0].y = [0, 0, ...traces[0].y, 0];
        }
      }
    }

    if (traces.length === 1) {
      //console.log(indicator, "trace:", traces);
      return traces;
    }

    let avg_x = traces.reduce((acc, trace) => {
      if (acc === undefined && trace) acc = [];
      acc = acc.concat(trace.x);
      return acc;
    }, []);

    avg_x = [...new Set(avg_x)];
    avg_x.sort();

    let avg_y = avg_x.map((year, index) => {
      let amount_y = traces.reduce((acc, trace) => {
        if (acc === undefined) acc = 0;
        if (trace && trace.x && trace.x.indexOf(year) !== -1) acc = acc + Number(trace.y[trace.x.indexOf(year)]);

        if (isNaN(acc)) {
          console.log("acc:", acc);
        }

        return acc;
      }, 0);

      if (isNaN(amount_y)) console.log("amount_y:", amount_y);
      return amount_y / traces.length;
    });

    traces.push({
      x: avg_x,
      y: avg_y,
      type: "bar",
      name: "Selected Countries(Average)",
      showlegend: true,
      marker: {
        color: "purple",
      },
    });

    //console.log(indicator, "trace:", traces);
    return traces;
  };

  return (
    <Layout>
      <Row className="mt-4 ">
        <Col xs={8}>
          <h3 className="pageTitle">Compare Countries</h3>
        </Col>
        <Col
          className=""
          xs={4}
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <span className="text-muted me-2">Legend</span>
          <ColorStatusLegend></ColorStatusLegend>
        </Col>
      </Row>

      <Row className="">
        <Col className="mt-3" xs={4}>
          <table>
            <tbody>
              {comparisonCountries.map((_country, index) => (
                <tr key={index}>
                  <td>
                    {_country.flag ? <img src={require("../flags/" + _country.flag + ".png")} className="me-3" height="28" width="54" /> : null}{" "}
                  </td>
                  <td>
                    <span className="title">{_country.name} </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
        <Col xs={4}></Col>
        <Col xs={4}></Col>
      </Row>

      <Row className="mt-5">
        <h4 className="title">Investment Priority</h4>
        <span className="description">
          Our suggested better country for investment/funding is the country with the lowest overall food security score.
        </span>
      </Row>

      <Row className="ms-1 mt-5">
        <table>
          <tbody>
            <tr>
              {comparisonCountries.map((_country, index) => (
                <th width={"" + 100 / countries.length + "%"} key={"i" + index}></th>
              ))}
            </tr>
            <tr>
              {comparisonCountries.map((_country, index) => (
                <td key={"ttd" + index}>
                  <h5 className="subTitle">
                    <strong>{index + 1 + ". " + _country.name} </strong>
                  </h5>
                </td>
              ))}
            </tr>
            <tr>
              {comparisonCountries.map((_country, index) => (
                <td key={"_td" + index}>
                  <div style={{ maxWidth: "120px", maxHeight: "120px", height: "100px" }}>
                    <ColorStatus value={_country["Food Security"]}></ColorStatus>
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Row>

      <HR className="mt-5" />

      <Row className=" mt-5">
        <h4 className="title">Data Availability Check</h4>
        <p className="description">
          Please note that the availability of data is not the same for all countries in the data set, which may affect the interpretation of the
          results. The data availability for the selected countries across the 23 indicators is as follows:
        </p>
        <Plot
          data={getDataAvailabilityChartData()}
          layout={{
            height: comparisonCountries.length * 80,
            width: 1000,
            barmode: "relative",
            bargap: 0.1,
            bargroupgap: 0.1,
            xaxis: {
              title: {
                text: "Indicators Covered",
                font: {
                  family: "Arial Black",
                  size: 14,
                },
              },
            },
            margin: {
              l: 450, // Adjust the left margin
              r: 0,
              t: 25,
            },
            legend: {
              title: {
                text: "#Countries with indicator status", // Set the legend title
                font: {
                  family: "Arial Black",
                  size: 14,
                },
              },
            },
          }}
        />
      </Row>

      <HR className="mt-5" />

      <Row className=" mt-5">
        <h4 className="title">Investment/Funding Areas</h4>
        <p className="description">
          Food security is broken down into four investment and funding areas, or pillars - availability, accessibility, utilization and governance.
          Within those four pillars, we classify each county based on mitigators (which help to ease the food insecurity conditions), and amplifiers
          (which worsen the food insecurity conditions).
        </p>
        <p className="description">
          Investment/funding areas are listed below. Recommended investment/funding areas are listed in bold. Click on the name of the
          investment/funding area to jump to that section.
        </p>
      </Row>

      <IndicatorBlock
        refForScrollTo={scrollToIndicatorBlockRef}
        isChartAvailable={(pillar, indicator) => isChartAvailable(state.countries, pillar, indicator)}
        isRecommentedIndicator={(countries, pillar, indicator) => isFundingArea(countries, pillar, indicator)}
        onClickInidcator={(pillar, indicator) => {
          console.log("onClickInidcator", pillar, indicator);
          gotoPillarIndicator(pillar, indicator);
        }}
        countries={comparisonCountries.map((c) => c.name)}
      />

      <FooterRow className="mt-5" />

      <HR />

      {getPillars().map((pillar, index) => (
        <div key={"pillar" + index}>
          <Row className="m-2 mt-5 ">
            <h4 className="title">{pillar}</h4>
            <p className="description">{getPillarsAbout(pillar)}</p>
          </Row>
          {getIndicatorNames(pillar).map((indicator, index) =>
            isChartAvailable(state.countries, pillar, indicator) ? (
              <IndicatorChart
                refToSrcoll={getPillarIndicatorRef(pillar, indicator)}
                key={"indicator" + index}
                returnTo={scrollToSelector}
                title={indicator}
                description={getIndicatorAbout(pillar, indicator)}
                chartData={getChartData(pillar, indicator)}
                layout={{
                  height: 400,
                  width: 600,
                  margin: {
                    l: 50, // Adjust the left margin
                    r: 0,
                    t: 0,
                  },
                  xaxis: {
                    //autorange: "reversed",
                    showgrid: false, // Hide the x-axis grid lines
                    tickformat: "d",
                    //range: getXRange(pillar, indicator),
                    autorange: "max",
                  },
                  yaxis: {
                    title: getIndicatorData(pillar, indicator).unit,
                    showgrid: false, // Hide the y-axis grid lines
                    //tickmode: 'auto',
                  },
                  legend: {
                    x: -0.05,
                    y: -1 * (0.05 + 0.1 * getChartData(pillar, indicator).length),
                    traceorder: "normal",
                  },
                }}
              />
            ) : null
          )}
        </div>
      ))}
    </Layout>
  );
};

export default Compare;
