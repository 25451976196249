import ColorStatus from "./ColorStatus";
import { Stack } from "react-bootstrap";

const ColorStatusLegend = ({ style, alignLeft }) => {
  return (
    <div
      style={
        alignLeft
          ? {
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }
          : {
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }
      }
    >
      <p
        style={{
          color: "white",
          fontSize: "0.85rem",
          marginTop: "16px",
          position: "absolute",
          ...(alignLeft ? { marginLeft: "7px" } : { marginRight: "164px" }),
        }}
      >
        Greater issue
      </p>
      <p
        style={{
          color: "black",
          fontSize: "0.85rem",
          ...(alignLeft ? { marginLeft: "110px" } : { marginRight: "106px" }),
          marginTop: "16px",
          position: "absolute",
        }}
      >
        Netural
      </p>
      <p
        style={{
          color: "white",
          fontSize: "0.85rem",
          marginLeft: "251px",
          marginTop: "16px",
          position: "absolute",
          ...(alignLeft ? { marginLeft: "177px" } : { marginRight: "6px" }),
        }}
      >
        Lesser issue
      </p>
      <Stack direction="horizontal" gap={0} style={style}>
        <ColorStatus value={-12} text="" height={38}></ColorStatus>
        <ColorStatus value={-8} text="" height={38}></ColorStatus>
        <ColorStatus value={-2} text="" height={38}></ColorStatus>
        <ColorStatus value={0} text="" height={38}></ColorStatus>
        <ColorStatus value={2} text="" height={38}></ColorStatus>
        <ColorStatus value={8} text="" height={38}></ColorStatus>
        <ColorStatus value={12} text="" height={38}></ColorStatus>
      </Stack>
    </div>
  );
};

export default ColorStatusLegend;
