import React, { useRef } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Stack, FormLabel, Form } from "react-bootstrap";
import ColorStatus from "../components/ColorStatus";
import ColorStatusLegend from "../components/ColorStatusLegend";
import Layout from "../components/Layout";
import Plot from "react-plotly.js";
import IndicatorBlock from "../components/IndicatorBlock";
import IndicatorChart from "../components/IndicatorChart";
import IndicatorChartWithBanchmark from "../components/IndicatorChartWithBanchmark";
import {
  getPillars,
  getIndicatorNames,
  getIndicatorData,
  getIndicatorChartData,
  getIndicatorAbout,
  isIndicatorChartValid,
  getIndicatorChartYheight,
  getPillarsAbout,
  getAllPillarIndicatorCombinations,
  getIndicatorChartAvaialbleCountryNumber,
  getAllCountryNumber,
} from "../util/common";
import HR from "../components/HR";

const Pillars = () => {
  const navigate = useNavigate();
  const scrollToIndicatorBlockRef = useRef(null);

  const pillarIndicatorRefs = useRef(getAllPillarIndicatorCombinations().map(() => React.createRef()));

  const getPillarIndicatorRef = (pillar, indicator) => {
    return pillarIndicatorRefs.current[getAllPillarIndicatorCombinations().findIndex((p) => p.pillar === pillar && p.indicator === indicator)];
  };

  const gotoPillarIndicator = (pillar, indicator) => {
    // console.log("gotoPillarIndicator", pillar, indicator);
    getPillarIndicatorRef(pillar, indicator).current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSelector = () => {
    if (scrollToIndicatorBlockRef.current) {
      scrollToIndicatorBlockRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Layout selectedTab={1}>
      <Row className="mt-4 ">
        <Col xs={8}>
          <h3 className="pageTitle">
            <strong>Food Security Pillars</strong>
          </h3>
        </Col>
        <Col
          className="px-4"
          xs={4}
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <span className="text-muted me-4">Legend</span>
          <ColorStatusLegend></ColorStatusLegend>
        </Col>
      </Row>
      <Row className="mt-4 ">
        <p className="description">
          Food security is broken down into four investment and funding areas, or pillars - availability, accessibility, utilization and governance.
          Within those four pillars, we classify each county based on indicators, which can be mitigators (which help to ease the food insecurity
          conditions), or amplifiers (which worsen the food insecurity conditions).
        </p>
      </Row>
      <Row className="mt-2 ">
        <p className="description">Click on the name of the indicator to jump to that section.</p>
      </Row>
      <IndicatorBlock
        id="selector"
        refForScrollTo={scrollToIndicatorBlockRef}
        isChartAvailable={isIndicatorChartValid}
        onClickInidcator={(pillar, indicator) => {
          console.log("onClickInidcator", pillar, indicator);
          gotoPillarIndicator(pillar, indicator);
        }}
      />
      <Row className="mt-5 ">
        <span style={{ fontSize: "11px" }}>
          Definitions of pillars are from HLPE. 2020. Food security and nutrition: building a global narrative towards 2030. A report by the High
          Level Panel of Exprts on Food Security and Nutrition of the Committee on World Food Security, Rome.
        </span>
      </Row>

      <HR className="mt-1 mb-1" />

      <Row className="mt-5 ">
        <h4 className="title">
          <strong>Overall</strong>
        </h4>
      </Row>
      <Row className="mt-1 ">
        <Col >
          <Stack>
            <span style={{ fontSize: 13 }}>
              There are 21 countries in the region. Please note that the availability of data is not the same for all countries in the data set, which
              may affect the interpretation of the results. The data availability across the 23 indicators is as follows.
            </span>
          </Stack>
        </Col>
        <Col >
          <Plot
            data={require("../data/chart-indicatorsOverall.json")}
            layout={{
              height: 700,
              width:800,
              barmode: "relative",
              bargap: 0.1,
              bargroupgap: 0.1,
              xaxis: {
                //autorange: 'reversed',
                title: {
                  text: "Indicators Covered",
                  font: {
                    family: "Arial Black",
                    size: 14,
                  },
                },
              },
              margin: {
                l: 450, // Adjust the left margin
                r: 0,
                t: 25,
              },
              legend: {
                title: {
                  text: "#Countries with indicator status", // Set the legend title
                  font: {
                    family: "Arial Black",
                    size: 14,
                  },
                },
                x: -0.05,
                y: -0.25
                ,
                traceorder: 'normal',
              },
            }}
          />
        </Col>
      </Row>

      <hr className="mt-1 mb-1" />
      {getPillars().map((pillar, p_index) => (
        <div key={"pillar" + p_index}>
          <Row className="mt-5 ">
            <h4 className="title">
              <strong>{pillar}</strong>
            </h4>
            <p className="description">{getPillarsAbout(pillar)}</p>
          </Row>

          {getIndicatorNames(pillar).map(
            (indicator, index) =>
              isIndicatorChartValid(pillar, indicator) && (
                <div key={"indicator" + indicator}>
                  <IndicatorChartWithBanchmark
                    refToSrcoll={getPillarIndicatorRef(pillar, indicator)}
                    returnTo={scrollToSelector}
                    title={indicator}
                    description={getIndicatorAbout(pillar, indicator)}
                    chartData={getIndicatorChartData(pillar, indicator)}
                    source={getIndicatorData(pillar, indicator).source}
                    unit={getIndicatorData(pillar, indicator).unit}
                    layout={{
                      // height: 700,
                      width: 850,
                      barmode: "relative",
                      bargap: 0.1,
                      showlegend: false,
                      xaxis: {
                        //autorange: 'reversed',
                        title: {
                          text: "Value",
                          font: {
                            family: "Arial Black",
                            size: 14,
                          },
                        },
                      },
                      margin: {
                        l: 250, // Adjust the left margin
                        r: 0,
                        t: 25,
                      },
                      shapes: [
                        {
                          type: "line",
                          x0: getIndicatorData(pillar, indicator).vline.x,
                          y0: -1,
                          x1: getIndicatorData(pillar, indicator).vline.x,
                          y1: getIndicatorChartYheight(pillar, indicator),
                          line: {
                            color: "blue",
                            width: 4,
                            dash: "dot",
                          },
                          label: {
                            text: getIndicatorData(pillar, indicator).vline.annotation_text,
                            font: { size: 14, color: "black" },
                            textposition: "top center",
                          },
                        },
                      ],
                    }}
                    availableCountryNumber={getIndicatorChartAvaialbleCountryNumber(pillar, indicator)}
                    totalCountryNumber={getAllCountryNumber()}
                    summaryTableData={getIndicatorData(pillar, indicator).table}
                  />

                  {index !== getIndicatorNames(pillar).length - 1 && <hr className="mt-1 mb-1" />}
                </div>
              )
          )}
          <HR className="mt-1 mb-1" />
        </div>
      ))}
    </Layout>
  );
};

export default Pillars;
