import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Stack, FormLabel, Form } from "react-bootstrap";
import ColorStatus from "../components/ColorStatus";
import ColorStatusLegend from "../components/ColorStatusLegend";
import Layout from "../components/Layout";
import PillarRow from "../components/PillarRow";
import FooterRow from "../components/FooterRow";
import HR from "../components/HR";
import {
  getPillars,
  getDefaultCountryName,
  getDefaultCountryCredibility,
  getCountryFlagShortName,
  getCountryCredibility,
  getCountryScore,
  getCountryMissingData,
  getAllCountryNames,
  getFundAreaOfCountry,
} from "../util/common";

const Home = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);

  const [countryName, setCountryName] = useState(state?.country ? state?.country : getDefaultCountryName());

  const [countryCredibility, setCountryCredibility] = useState(getDefaultCountryCredibility(countryName));

  let keyInvestmentAreas = getFundAreaOfCountry(countryName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Row className="mt-5">
        <Col xs={8}>
          <h2 style={{ fontWeight: "800" }}>
            <span className="pageTitle">
              {getCountryFlagShortName() ? (
                <img src={require("../flags/" + getCountryFlagShortName() + ".png")} height="32" style={{ marginRight: "20px" }} />
              ) : null}
              {countryName}
            </span>
          </h2>
        </Col>
        <Col className="" xs={4}>
          <Stack direction="horizontal" gap={1} style={{ height: "100%", display: "flex", justifyContent: "right", alignItems: "center" }}>
            <span className="me-1">Legend</span>
            <ColorStatusLegend alignLeft={false}></ColorStatusLegend>
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col xs={6}></Col>
        <Col xs={6}>
          <Stack direction="horizontal" gap={0} style={{ height: "100%", display: "flex", justifyContent: "right", alignItems: "center" }}>
            <FormLabel className="darkblue field-label mb-1 me-1">View another country</FormLabel>
            <Form.Select
              style={{ width: "267px" }}
              value={countryName}
              onChange={(e) => {
                setCountryCredibility(getCountryCredibility(e.target.value));
                setCountryName(e.target.value);
              }}
            >
              {getAllCountryNames().map((_country, index) => (
                <option key={"county-" + index} value={_country}>
                  {_country}
                </option>
              ))}
            </Form.Select>
          </Stack>
        </Col>
      </Row>

      <Row className="">
        <Col className="text-center " style={{ height: "96px" }}>
          <Stack direction="horizontal" gap={0} style={{ height: "100%" }}>
            <ColorStatus value={getCountryScore(countryName, "Food Security")} width="123px"></ColorStatus>
            <h4
              className="ms-4 title"
              style={{
                display: "flex",
                justifyContent: "left",
                height: "100%",
                alignItems: "center",
              }}
            >
              Overall Food Security Score
            </h4>
          </Stack>
        </Col>
        <Col xs={5}></Col>
      </Row>

      <HR className="mt-5" />

      <Row className="mt-5">
        <h4 className="title">Investment/Funding Areas</h4>
        <p className="mt-2" style={{ fontSize: 13 }}>
          Food security is broken down into four investment and funding areas, or pillars - availability, accessibility, utilization and governance.
          Within those four pillars, we classify each county based on mitigators (which help to ease the food insecurity conditions), and amplifiers
          (which worsen the food insecurity conditions).
        </p>
      </Row>

      <Row className="mt-3 mb-1">
        <h5 className="subTitle">Key Investment Areas for this Country</h5>
      </Row>

      <Row className="mb-4">
        {getPillars().map((pillar, index) => (
          <Col key={"key-investment-pillar" + index} xs={3}>
            <p style={{ textDecoration: "underline" }}>{pillar}</p>
            {keyInvestmentAreas[pillar] ? (
              keyInvestmentAreas[pillar].map((indicator, _index) => (
                <p key={"key-investment-pillar-indicator" + _index} style={{ fontSize: 13, marginBottom: 3 }}>
                  {indicator}
                </p>
              ))
            ) : (
              <p style={{ fontSize: 13 }}>None</p>
            )}
          </Col>
        ))}
      </Row>

      <hr className=" mb-3" />

      {getPillars().map((pillar, index) => (
        <div key={"pillar" + index}>
          <PillarRow
            className="mb-5"
            countryName={countryName}
            countryScore={getCountryScore(countryName)}
            credibility={getCountryCredibility(countryName)}
            missingData={getCountryMissingData(countryName, pillar)}
            pillar={pillar}
          ></PillarRow>
          {index !== getPillars().length - 1 ? <hr className=" mb-5" /> : null}
        </div>
      ))}

      <HR className="mt-5" />

      <FooterRow />
    </Layout>
  );
};

export default Home;
